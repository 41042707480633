@import "../../../assets/scss/variables.scss";

.feedback {
  // height: 100vh;
  background: $primary-color-grey;
  overflow: hidden;
  position: relative;

  & > .wrapper {
    margin: auto;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    padding-top: 100px;
    padding-bottom: 50px;
    color: white;

    & > .allReviews {
      padding-bottom: 50px;

      .eachReview {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > .review {
          font-size: 2rem;
          line-height: 1.6;
          max-width: 800px;
          text-align: center;
        }

        & > .dividerBoxes {
          position: relative;
          width: 1rem;
          height: 1rem;
          border: 1px solid $font-color-dark;
          transform: rotate(50deg);
          margin: 2rem 0;
          background: $font-color-dark;
          transition: all linear 0.3s;

          &::before {
            content: "";
            position: absolute;
            width: 100% !important ;
            height: 100% !important;
            border: 1px solid $font-color-dark;
            left: 110%;
            top: -20%;
            transform: translate(-50%, -50%) rotate(0deg);
          }
          &::after {
            content: "";
            position: absolute;
            width: 100% !important ;
            height: 100% !important;
            border: 1px solid $font-color-dark;
            top: 120%;
            right: -5%;
            transform: translate(-50%, -50%) rotate(0deg);
          }
        }

        & > .profileImage {
          width: 6rem;
          height: 6rem;
          border-radius: 50%;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          color: $font-color-dark;
          font-size: 2.5rem;

          & > img {
          }
        }

        & > .profileName {
          font-size: 0.9rem;
          font-weight: 500;
          text-transform: uppercase;
          color: $font-color-dark;
          margin-top: 1.5rem;
          letter-spacing: 3px;
        }
      }
    }

    & > .newReview {
      background: $primary-color-dark;
      width: 100%;
      max-width: 1000px;
      display: flex;

      & > div {
        // height: 100%;
        padding: 3rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        & > .title {
          font-size: 2.2rem;
          font-weight: 500;
          margin-bottom: 1rem;

          & > .desc {
            font-size: 0.9rem;
            margin-top: 0.5rem;

            & > span {
              color: $font-color-dark;
            }
          }
        }

        & > input,
        textarea {
          padding: 1rem;
          background: $primary-color-grey;
          border: 1.5px solid $primary-color-dark;
          outline: none;
          color: white;
          font-family: inherit;
          max-width: 100%;
          transition: all linear 0.2s;

          &:focus {
            border: 1.5px solid $font-color-dark;
          }
        }

        & > .submitBtn {
          padding: 0.65rem 2rem;
          font-size: 0.9rem;
          font-weight: 500;
          background-color: $font-color-dark;
          color: white;
          cursor: pointer;
          width: max-content;
        }
      }

      & > .writeReview {
        width: calc(100% - 320px);
      }

      & > .contactBox {
        width: 320px;
        background-color: #000000;
        opacity: 0.8;
        background-image: linear-gradient(
            30deg,
            #252526 12%,
            transparent 12.5%,
            transparent 87%,
            #252526 87.5%,
            #252526
          ),
          linear-gradient(
            150deg,
            #252526 12%,
            transparent 12.5%,
            transparent 87%,
            #252526 87.5%,
            #252526
          ),
          linear-gradient(
            30deg,
            #252526 12%,
            transparent 12.5%,
            transparent 87%,
            #252526 87.5%,
            #252526
          ),
          linear-gradient(
            150deg,
            #252526 12%,
            transparent 12.5%,
            transparent 87%,
            #252526 87.5%,
            #252526
          ),
          linear-gradient(
            60deg,
            #25252677 25%,
            transparent 25.5%,
            transparent 75%,
            #25252677 75%,
            #25252677
          ),
          linear-gradient(
            60deg,
            #25252677 25%,
            transparent 25.5%,
            transparent 75%,
            #25252677 75%,
            #25252677
          );
        background-size: 20px 35px;
        background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
        color: white;
        align-items: center;

        & > .phone {
          text-align: center;
          & > .title {
            font-size: 0.9rem;
            margin-bottom: 0.3rem;
          }

          & > .value {
            font-size: 1.5rem;
            color: $font-color-dark;
            font-weight: 550;
          }
        }

        & > .divider {
          position: relative;
          width: 15px;
          height: 15px;
          border: 1px solid $border-color-dark;
          transform: rotate(50deg);
          transition: all linear 0.3s;
        }

        & > .otherDetails {
          text-align: center;
          font-size: 0.9rem;

          & > .title {
            margin-bottom: 0.3rem;
          }

          & > .value {
            color: rgb(152, 151, 151);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 860px) {
  /* CSS rules for extra small devices */

  .feedback {
    // padding: 0 10px;

    & > .wrapper {
      padding-bottom: 0;

      & > .allReviews {
        padding: 20px 0;
      }

      & > .newReview {
        flex-direction: column;

        & > div {
          width: 100% !important;
        }
      }
    }
  }
}

// animation: rotate 2s linear infinite;
// @keyframes rotate {
//             from {
//               transform: rotate(0deg);
//             }
//             to {
//               transform: rotate(360deg);
//             }
//           }
