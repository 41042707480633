@import "../../../assets/scss/variables.scss";

.eachDish {
  border: 1px solid blue;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 1rem;
  width: 280px;
}

.imageDiv {
  // height: 50%;
  width: 100%;
  line-height: 0;
  border: 1px solid black;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 1rem 0;

  img {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    border: 2px solid white;
  }

  .price {
    // height: max-content;
    // height: 1rem;
    font-size: 0.9rem;
    line-height: unset;
    background: white;
    padding: 5px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    color: $font-color-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
    font-weight: 500;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    width: 3rem;
    height: 3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dishName {
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  height: unset;
  line-height: normal;
  padding: 0.5rem 1rem;
  background: $font-color-dark;
  color: white;
  width: 80%;
  position: relative;
  // overflow: hidden;
  margin: 0.25rem 0;
  border-radius: 5px;
  border: 1px solid $primary-color-dark;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   // width: 100%;
  //   height: 90%;
  //   width: 3rem;
  //   top: 0;
  //   bottom: 0;
  //   left: -7px;
  //   background-color: $primary-color-dark;
  //   clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  //   transform: rotate(90deg);
  // }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   // width: 100%;
  //   height: 90%;
  //   width: 3rem;
  //   top: 0;
  //   bottom: 0;
  //   right: -7px;
  //   background-color: $primary-color-dark;
  //   clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  //   transform: rotate(-90deg);
  // }
}

.dishDesc {
  height: unset;
  font-size: 0.9rem;
  line-height: normal;
  margin: 0.5rem 0;
}
