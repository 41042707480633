@import "../../../assets/scss/variables.scss";

.menuCarousal {
  background: $primary-color-dark;
  overflow: hidden;
  position: relative;
  height: 100vh;

  & > .wrapper {
    margin: auto;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    padding-top: 100px;
    max-height: 1500px;
    height: 100%;

    & > .welcomeText {
      font-family: "Playfair Display", serif;
      font-size: 1.2rem;
      font-weight: 500;
      position: relative;
      margin-bottom: 3rem;
      color: $font-color-dark;

      & > svg {
        width: 500px;
        max-height: 100px;
        fill: white;
        position: absolute;
        top: calc(100% + 15px);
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    & > .carousalContainer {
      width: 100%;
      height: 80%;
      padding-bottom: 1rem;

      button {
        display: none;
      }

      & > div {
        & > div {
          max-width: unset !important;
          margin: auto;

          & > div {
            gap: 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 860px) {
  /* CSS rules for extra small devices */

  .menuCarousal {
    padding: 0 10px;

    & > .wrapper {
      padding: 20px 0;
    }
  }
}
