@import "../../assets/scss/variables.scss";

/* Button CSS */
.button-52 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 10px 20px 10px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: $font-color-dark;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  color: black;
  font-weight: 500;
  border-radius: 7px;
  text-transform: uppercase;
}

.button-52:after {
  content: "";
  background-color: #ffffff;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
  border-radius: 7px;
}

.button-52:hover:after {
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .button-52 {
    padding: 13px 50px 13px;
  }
}
