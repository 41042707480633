@import "../../assets/scss/variables.scss";

.home {
  // height: 100vh;
  // width: 100vw;
  // overflow-x: hidden;
  position: relative;

  & > .offerCard {
    position: fixed;
    padding: 3rem;
    background: white;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 2/0.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.75rem;
    background-color: #000000;
    // opacity: 0.8;
    background-image: linear-gradient(
        30deg,
        #252526 12%,
        transparent 12.5%,
        transparent 87%,
        #252526 87.5%,
        #252526
      ),
      linear-gradient(
        150deg,
        #252526 12%,
        transparent 12.5%,
        transparent 87%,
        #252526 87.5%,
        #252526
      ),
      linear-gradient(
        30deg,
        #252526 12%,
        transparent 12.5%,
        transparent 87%,
        #252526 87.5%,
        #252526
      ),
      linear-gradient(
        150deg,
        #252526 12%,
        transparent 12.5%,
        transparent 87%,
        #252526 87.5%,
        #252526
      ),
      linear-gradient(
        60deg,
        rgba(37, 37, 38, 0.4666666667) 25%,
        transparent 25.5%,
        transparent 75%,
        rgba(37, 37, 38, 0.4666666667) 75%,
        rgba(37, 37, 38, 0.4666666667)
      ),
      linear-gradient(
        60deg,
        rgba(37, 37, 38, 0.4666666667) 25%,
        transparent 25.5%,
        transparent 75%,
        rgba(37, 37, 38, 0.4666666667) 75%,
        rgba(37, 37, 38, 0.4666666667)
      );
    background-size: 20px 35px;
    background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
    border-radius: 7px;
    box-shadow: $font-color-dark 0px 10px 36px 0px,
      $font-color-dark 0px 0px 0px 1px;
    rotate: -3deg;
    font-family: "Truculenta", sans-serif;

    & > .title {
      font-size: 3rem;
      font-weight: 500;
      color: $font-color-dark;
      color: white;
    }
    & > .description {
      font-size: 2rem;
      font-weight: 500;
      color: $font-color-dark;
      text-align: center;
      color: white;
    }

    & > .offer {
      font-size: 1.2rem;
      font-weight: 500;
      color: white;
      border: 1px solid $border-color-dark;
      padding: 1.2rem;
      // width: 8rem;
      // height: 8rem;
      // border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background: $font-color-dark;
      top: calc(100% - 2rem);
      right: 2rem;
      border-radius: 6px;
      rotate: -0.5deg;

      &::before {
        content: "";
        position: absolute;
        border: 1px solid white;
        inset: -3px;
        border-radius: 6px;
      }
      &::after {
        content: "";
        position: absolute;
        border: 1px solid white;
        inset: -5px;
        border-radius: 6px;
      }
    }

    & > .closeBtn {
      font-size: 1.2rem;
      position: absolute;
      right: 1.5rem;
      top: 1rem;
      color: white;
      cursor: pointer;
      transition: all linear 0.3s;

      &:hover {
        color: $font-color-light;
      }
    }
  }

  & > .offerPoster {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 60%;
    // height: 60%;
    min-width: 300px;
    max-width: 95%;

    & > img {
      position: absolute;
      width: 60%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 300px;
      max-width: 95%;
    }

    & > .closeBtn {
      font-size: 1.2rem;
      position: absolute;
      right: 1.5rem;
      top: 1rem;
      color: black;
      font-weight: 520;
      cursor: pointer;
      transition: all linear 0.3s;

      &:hover {
        color: $font-color-light;
      }
    }
  }
}

@media only screen and (max-width: 860px) {
  /* CSS rules for extra small devices */

  .home {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;

    & > .offerCard {
      rotate: 0deg;
      width: calc(100% - 2rem);
    }
  }
}
