@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sorts+Mill+Goudy:ital@0;1&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sorts+Mill+Goudy:ital@0;1&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Truculenta:opsz,wght@12..72,100..900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* For WebKit-based browsers (Chrome, Safari, Opera) */
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the track (area behind the scrollbar thumb) */
}

::-webkit-scrollbar-thumb {
  background-color: #d08945; /* Color of the scrollbar thumb */
  border-radius: 2.5px; /* Rounded corners of the scrollbar thumb */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Width of the scrollbar */
  scrollbar-color: #d08945 transparent; /* Color of the scrollbar thumb and track */
}

body {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
}

:root {
  --animate-duration: 5s;
}

@media only screen and (max-width: 860px) {
  /* CSS rules for extra small devices */

  #root {
    max-width: 100vw;
    overflow-x: hidden;
  }
}
