@import "../../assets/scss/variables.scss";

.dashboardLayout {
  display: flex;
  background: $font-color-dark;
  height: 100vh;

  & > .leftSidebar {
    min-width: 200px;
    max-width: 300px;
    width: 30%;
    padding: 2rem;

    & > .logo {
      display: flex;
      justify-items: flex-start;

      & > img {
        max-width: 100%;
        height: 4rem;
        translate: -15px;
      }
    }

    & > .dashItems {
      margin-top: 2.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      height: calc(100% - 6.5rem);

      & > .sidebarItem {
        cursor: pointer;
        color: #ffffff;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 1rem;
        padding: 0.5rem 0;
        transition: all linear 0.3s;
      }

      & > .selectedItem {
        text-decoration: underline;
      }

      & > .otherItems {
        border-top: 1px solid #949393;
        padding: 0.5rem 0;
        padding-top: 1.7rem;
      }

      & > .logoutBtn {
        margin-top: auto;
        transition: all linear 0.3s;

        &:hover {
          color: white;
          text-decoration: underline;
        }
      }
    }
  }

  & > .rightLayout {
    background: white;
    width: -webkit-fill-available;
  }
}
