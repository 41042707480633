@import "../../assets/scss/variables.scss";

.navbar {
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  color: white;
  background: transparent;

  &::before {
    content: "";
    position: absolute;
    height: 0.5px;
    //   width: 100%;
    top: 52px;
    background: $border-color-dark;
    left: 0;
    right: 0;
    opacity: 0.5;
  }

  & > .wrapper {
    margin: auto;
    max-width: 1600px;
    position: relative;
    padding: 0 1rem;

    & > .contactNav {
      height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.9rem;

      & > .info,
      .contact {
        display: flex;
        align-items: center;
        gap: 1.5rem;

        & > div {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        & > svg {
          //   color: $font-color-dark;
          opacity: 0.6;
        }
      }
    }

    & > .navigationNav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;

      & > .logo {
        display: flex;
        width: 250px;
        cursor: pointer;

        & > img {
          height: 60px;
        }

        & > .singleLetter {
          font-size: 3.5rem;
          line-height: 100%;
          margin-left: 5px;
        }

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: left;
          line-height: 130%;
          letter-spacing: 1px;
          font-family: "Archivo", sans-serif;
          font-variation-settings: "wdth" 100;
          color: #9f4a03;

          & > :nth-child(1) {
            font-size: 1.4rem;
          }

          & > :nth-child(2) {
            font-size: 1rem;
            letter-spacing: 2px;
          }
        }
      }

      & > .navItems {
        display: flex;
        gap: 2rem;
        align-items: center;
        text-transform: uppercase;

        & > div {
          cursor: pointer;
          position: relative;
          transition: all linear 0.3s;

          &::before {
            content: "";
            position: absolute;
            width: 0%;
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: -50%;
            height: 0.5px;
            background: $font-color-dark;
            opacity: 0;
            transition: all linear 0.3s;
          }

          &::after {
            content: "";
            position: absolute;
            width: 0%;
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: -35%;
            height: 0.5px;
            background: $font-color-dark;
            opacity: 0;
            transition: all linear 0.3s;
          }

          &:hover {
            color: $font-color-dark;
          }
        }

        & > .selectedNav {
          color: $font-color-dark;

          &::before {
            opacity: 1;
            width: 100%;
          }

          &::after {
            opacity: 1;
            width: 100%;
          }
        }
      }

      & > .generalBtn {
        width: 200px;
        background: $font-color-dark;
        height: 55%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        text-transform: uppercase;
        color: black;
        font-weight: 550;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 860px) {
  /* CSS rules for extra small devices */

  .navbar {
    display: none;
  }
}
