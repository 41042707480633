$primary-color-dark: #070708;
$secondary-color-dark: #19191a;
$primary-color-grey: #252526;
$border-color-dark: #eeeeee;
$font-color-dark: #d08945;
$font-color-light: #e3b080;

$primary-color-light: #ffffff;
$secondary-color-dark: #f0f0f0;
$border-color-dark: #888888;

$white-text: #ffffff;
$black-text: #000000;
