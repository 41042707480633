@import "./assets/scss/variables.scss";

.App {
  text-align: center;
  /* width: 100vw; */
}

.mainCompoent {
  background: rgb(114, 56, 3);
  background: radial-gradient(
    circle,
    rgba(114, 56, 3, 1) 0%,
    rgb(225, 141, 63) 86%,
    rgba(166, 143, 124, 1) 100%
  );
  color: white;
  overflow-y: scroll;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* For Webkit based browsers like Chrome and Safari */
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  width: 12px; /* Adjust the width as needed */
}

body::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide the thumb */
}

body::-webkit-scrollbar-track {
  background-color: transparent; /* Hide the track */
}
