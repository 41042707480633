@import "../../assets/scss/variables.scss";

.menuu {
  position: relative;
  padding: 2rem;
  height: 100%;
  overflow: hidden;

  & > .topBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    & > .searchOption {
      padding: 1rem 1.5rem;
      border: 1px solid grey;
      min-width: 450px;
      border-radius: 8px;
      outline: none;

      &:focus {
        border: 1px solid $font-color-dark;
      }
    }

    & > .addClient {
      background: $font-color-dark;
      color: white;
      padding: 1rem 2rem;
      border-radius: 35px;
      margin-left: auto;
      width: max-content;
      cursor: pointer;
      outline: none;
      border: none;
    }
  }

  & > .tableContainer {
    height: calc(100% - 82px);
    overflow-y: scroll;

    & > table {
      width: 100%;
      height: 100%;

      th,
      td {
        padding: 1rem;
        text-align: left;
      }

      thead {
        background: $font-color-dark;
        color: white;
      }

      tbody {
        border: 1px solid #e5e5e5;
        overflow-y: scroll;
        height: calc(100% - 52px);
        border: 1px solid green;

        & > tr {
          border-bottom: 1px solid #e5e5e5;

          & :nth-child(6) {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $font-color-dark;
            font-size: 1.2rem;
            cursor: pointer;
          }
        }

        & > :last-child {
          border: none;
        }

        td {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      tr {
        display: grid;
        grid-template-columns: 1fr 1fr 100px 1.3fr 0.7fr 100px;
      }
    }
  }

  & > .nextRemainder {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;

    & > .overlayContainer {
      position: absolute;
      background: #00000080;
      inset: 0;
      // z-index: 1;
    }

    & > .increaseContainer {
      background: white;
      padding: 3rem;
      // border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;

      & > .title {
        font-size: 2rem;
        font-weight: 500;
        color: $font-color-dark;
      }

      & > :nth-child(2) {
        margin: 1rem 0;
      }

      & > .addinventory {
        background: $font-color-dark;
        color: white;
        padding: 1rem 2rem;
        border-radius: 10px;
        margin-left: auto;
        width: max-content;
        cursor: pointer;
        outline: none;
        border: none;
        margin-top: 1rem;
      }

      & > .inventoryFields {
        width: 325px;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > button {
          margin-top: 2rem;
        }
      }
    }
  }

  & > .addItem {
    position: absolute;
    padding: 2rem;
    border: 1px solid #e1e1e1;
    border-radius: 7px;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    overflow-y: scroll;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    // aspect-ratio: 2/1;

    & > .title {
      font-size: 2rem;
      color: $font-color-dark;
    }

    & > .inputContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;

      & > :last-child {
        ::-webkit-scrollbar {
          width: 0.5em; /* Adjust the width as needed */
          background-color: transparent; /* Adjust the background color as needed */
          display: none;
        }

        ::-webkit-scrollbar-thumb {
          background-color: transparent; /* Adjust the thumb color as needed */
          display: none;
        }
      }
    }

    & > .submitBtn {
      padding: 0.6rem 1.5rem;
      background: $font-color-dark;
      color: white;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      border-radius: 7px;
      margin-top: 1.5rem;
      width: max-content;
    }

    & > .loadingAnimation {
      position: absolute;
      inset: 0;
      background: #00000080;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      z-index: 2;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        width: 6rem;
        height: 6rem;
        animation: heartbeat 1s infinite;

        @keyframes heartbeat {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.2);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
  }
}
